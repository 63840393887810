import React, { useState, useRef, useEffect } from "react";
import "./home.scss";
import Title from "components/texts/title";
import { Fact, NavButton, Text } from "components";
import { PATHS } from "router/paths";

import { Travel, Programming, Country, Logos, BGVid, BGImgae } from "./images";
import Marquee from "react-fast-marquee";

const Home = () => {
	const [videoError, setVideoError] = useState(false);

	const refVideo = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		if (!refVideo.current) {
			return;
		}
		refVideo.current.defaultMuted = true;
		refVideo.current.muted = true;
		refVideo.current.volume = 0;
		refVideo.current.loop = true;
		refVideo.current.autoplay = true;
		refVideo.current.playsInline = true;
	}, []);

	return (
		<main className={"home"}>
			<article className={"welcome"}>
				{videoError ? (
					<img src={BGImgae} className='video' alt='Загрузка' />
				) : (
					<video
						className={"video"}
						ref={refVideo}
						onError={() => setVideoError(true)}
					>
						<source type='video/mp4' src={BGVid} />
						Ваш браузер не поддерживает видео
					</video>
				)}
				<Title type={"h1"} theme={"white"}>
					Технологии мониторинга полётных данных
				</Title>
				<Text theme={"white"}>
					Средства, системы и программное обеспечение для организации
					сбора, обработки и использования полетной информации.
				</Text>
				<div className={"button-box"}>
					<NavButton
						href={PATHS.CATALOG}
						type={"button"}
						theme={"btn-gray"}
					>
						Каталог
					</NavButton>
					<NavButton
						href={PATHS.LUCH}
						type={"button"}
						theme={"btn-green"}
					>
						Специальное ПО
					</NavButton>
				</div>
			</article>
			<article className={"short-about"}>
				<Title type={"h2"}>Несколько слов о компании</Title>
				<Text>
					Компания «Технологии мониторинга полетных данных» («ТМПД»)
					является производителем оборудования авионики, твердотельных
					накопителей параметрических и звуковых данных,
					эксплуатационных бортовых накопителей (ЭБН). Наша компания
					предлагает инновационные технологии в области авионики,
					программного обеспечения и имеет более чем 30-летний опыт в
					сфере обеспечения безопасности полётов.
				</Text>
				<section className={"facts"}>
					<Fact src={Travel} alt={"Самолёт"} title={"Более 2 млн"}>
						Столько рейсов обработано нашими продуктами и ПО
					</Fact>
					<Fact
						src={Programming}
						alt={"Программный код"}
						title={"Более 300 типов"}
					>
						Самолётов и самописцев используют наше ПО
						&quot;ЦИФРА&quot;
					</Fact>
					<Fact src={Country} alt={"Земля"} title={"Более 50 стран"}>
						Пользуются нашими разработками
					</Fact>
				</section>
			</article>
			<article className={"partners"}>
				<Title type={"h2"} theme={"white"}>
					Наши партнёры
				</Title>
				<Marquee
					autoFill={true}
					className={"marquee"}
					gradient={true}
					gradientWidth={50}
					gradientColor={"#5fc5bd"}
				>
					{Object.keys(Logos).map((key) => (
						<>
							<img
								className={
									"logo-img" +
									(key === "MAK" || key === "Polar"
										? " x2"
										: "")
								}
								src={Logos[key].src}
								alt={Logos[key].alt}
							/>
						</>
					))}
					<Title type={"h3"} theme={"white"}>
						МГТУГА
					</Title>
					<Title type={"h3"} theme={"white"}>
						Avialift Vladivostok
					</Title>
				</Marquee>
			</article>
		</main>
	);
};

export default Home;
