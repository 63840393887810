import React from "react";
import "./luch.scss";
import { Text, Title } from "components";
import { WorkImg, LuchImg, ExelImg, FlyImg } from "./images";
const Luch = () => {
	return (
		<main className={"luch"}>
			<Title type={"h2"} theme={"white"} className={"page-name"}>
				СПО ЦИФРА
			</Title>
			<section className={"base-text"}>
				<Title type={"h2"}>Программа анализа полёта данных</Title>
				<Text>
					СПО «ЦИФРА» предназначено для алгоритмической обработки и
					анализа информации, зарегистрированной на самолетах
					бортовыми средствами регистрации параметров. Введена в
					эксплуатацию серверная версия СПО «ЦИФРА», позволяющая
					проводить обработку онлайн, имея соответствующий допуск.
					Загрузка полётов на обработку производится либо выбором
					оператора, либо в настроенном компанией автоматическом
					режиме. Возможность автоматической отправки отчёта о
					результатах обработки на заранее настроенный адрес
					электронной почты.
				</Text>

				<div className={"function"}>
					<img
						className={"image"}
						src={WorkImg}
						alt={"Работающая женщина"}
					/>
					<div className={"text-block"}>
						<Title type={"h3"}>
							СПО «ЦИФРА» реализует следующие основные функции:
						</Title>
						<ul className={"list"}>
							<li>
								<Text>
									Приём копии полёта, зарегистрированной
									бортовой системой;
								</Text>
							</li>
							<li>
								<Text>
									Конвертирование копии полёта в табличный вид
									формата MySQL;
								</Text>
							</li>
							<li>
								<Text>
									Экспресс-анализ полёта по заданным
									алгоритмам;
								</Text>
							</li>
							<li>
								<Text>
									Вывод результатов анализа в табличном,
									графическом и статистическом видах;
								</Text>
							</li>
							<li>
								<Text>
									Для визуализации работы с СПО применены
									стандартные средства браузеров Opera, Google
									Chrome или Mozilla FireFox.
								</Text>
							</li>
						</ul>
					</div>
				</div>
			</section>
			<section className={"analytics"}>
				<div className={"text-block"}>
					<Title type={"h3"}>
						Аналитические возможности, доступные в результате
						использования СПО
					</Title>
					<ul className={"list"}>
						<li>
							<Text>
								Углубленный анализ информации по алгоритмам,
								использующим заранее подготовленные данные о
								метеоусловиях во время полетов, об особенностях
								аэродрома взлета и посадки и об индивидуальных
								особенностях ВС;
							</Text>
						</li>
						<li>
							<Text>
								Выполнение расчетов любой сложности, включая
								решение дифференциальных уравнений динамики
								полета, и построения дополнительных расчетных
								параметров полета, таких как вертикальная
								скорость, угол наклона траектории и другие;
							</Text>
						</li>
						<li>
							<Text>Логическая обработка полученных данных;</Text>
						</li>
						<li>
							<Text>
								Анализ результатов в графическом и цифровом
								виде;
							</Text>
						</li>
						<li>
							<Text>
								Обобщение и статистическая обработка баз данных;
							</Text>
						</li>
						<li>
							<Text>
								Возможность синхронизация параметрической и
								речевой информации.
							</Text>
						</li>
					</ul>
				</div>
				<div className={"image-block"}>
					<img src={ExelImg} alt={"Exel"} />
					<img src={LuchImg} alt={"Luch"} />
				</div>
			</section>
			<section className={"logic"}>
				<Title type={"h3"}>
					Функции логической обработки, выполняемые СПО
				</Title>
				<ul className={"list"}>
					<li>
						<Text>
							Цифровая фильтрация параметров полета, поиск и
							отбраковка сбоев, расчет значений, нерегистрируемых
							бортовой системой;
						</Text>
					</li>
					<li>
						<Text>
							Контроль выходов за ограничения согласно документам,
							регламентирующим летную деятельность;
						</Text>
					</li>
					<li>
						<Text>
							Контроль качества пилотирования и причин, приводящих
							к его снижению;
						</Text>
					</li>
					<li>
						<Text>
							Контроль технического состояния бортового
							оборудования;
						</Text>
					</li>
					<li>
						<Text>
							Вспомогательные вычисления, повышающие достоверность
							обработки;
						</Text>
					</li>
					<li>
						<Text>
							Возможность дополнительных исследований и расчётов
							процесса выполнения полета.
						</Text>
					</li>
				</ul>
			</section>
			<section className={"features"}>
				<div className={"text-block"}>
					<Title type={"h3"}>
						Аналитические возможности, доступные в результате
						использования СПО
					</Title>
					<ul className={"list"}>
						<li>
							<Text>
								Углубленный анализ информации по алгоритмам,
								использующим заранее подготовленные данные о
								метеоусловиях во время полетов, об особенностях
								аэродрома взлета и посадки и об индивидуальных
								особенностях ВС;
							</Text>
						</li>
						<li>
							<Text>
								Выполнение расчетов любой сложности, включая
								решение дифференциальных уравнений динамики
								полета, и построения дополнительных расчетных
								параметров полета, таких как вертикальная
								скорость, угол наклона траектории и другие;
							</Text>
						</li>
						<li>
							<Text>Логическая обработка полученных данных;</Text>
						</li>
						<li>
							<Text>
								Анализ результатов в графическом и цифровом
								виде;
							</Text>
						</li>
						<li>
							<Text>
								Обобщение и статистическая обработка баз данных;
							</Text>
						</li>
						<li>
							<Text>
								Возможность синхронизация параметрической и
								речевой информации.
							</Text>
						</li>
					</ul>
					<Text>
						<i>
							Применение интерфейса с несколькими популярными
							языками (французский, английский, испанский) с
							возможностью дополнения по заявке Заказчика.
						</i>
					</Text>
				</div>
				<img className={"image"} src={FlyImg} alt={"Летит самолёт"} />
			</section>
		</main>
	);
};

export default Luch;
