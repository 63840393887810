import React, { useEffect, useState, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import useOutsideClick from "libs/OutsieClick";

import { PATHS } from "router/paths";
import { NavButton } from "components/buttons";

import "./header.scss";
import Logo from "./images/logo.png";
import BurgerIcon from "./images/burger-icon.svg";
import CrossIcon from "./images/cross.svg";

const Header = () => {
	const [open, setOpen] = useState(false);
	const navRef = useRef(null);
	const currentLoc = useLocation();

	useOutsideClick(navRef, () => {
		setOpen(false);
	});

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth > 600) {
				setOpen(false);
			}
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		setOpen(false);
	}, [currentLoc]);

	return (
		<header className={"header"}>
			<nav className={"navigation"}>
				<NavLink to={PATHS.HOME}>
					<img
						className={"logo"}
						src={Logo}
						alt={"Технологии Мониторинга Полётных Данных"}
					/>
				</NavLink>
				<ul className={"list"}>
					<li>
						<NavButton href={PATHS.HOME} type={"text"}>
							Главная
						</NavButton>
					</li>
					<li>
						<NavButton href={PATHS.ABOUT} type={"text"}>
							О компании
						</NavButton>
					</li>
					<li>
						<NavButton href={PATHS.LUCH} type={"text"}>
							СПО ЦИФРА
						</NavButton>
					</li>
					<li>
						<NavButton href={PATHS.CATALOG} type={"text"}>
							Каталог
						</NavButton>
					</li>
					<li>
						<NavButton href={PATHS.CONTACT} type={"text"}>
							Контакты
						</NavButton>
					</li>
				</ul>
				<img
					className={"burger"}
					src={BurgerIcon}
					alt={"Открыть меню"}
					onClick={() => setOpen(!open)}
				/>
			</nav>
			<nav className={"nav-mobile" + (open ? " blur" : "")}>
				<div ref={navRef} className={"menu" + (open ? " open" : "")}>
					<img
						src={CrossIcon}
						alt={"Закрыть меню"}
						className={"cross"}
						onClick={() => setOpen(!open)}
					/>
					<ul className={"list"}>
						<li>
							<NavButton href={PATHS.HOME} type={"text"}>
								Главная
							</NavButton>
						</li>
						<li>
							<NavButton href={PATHS.ABOUT} type={"text"}>
								О компании
							</NavButton>
						</li>
						<li>
							<NavButton href={PATHS.LUCH} type={"text"}>
								СПО ЦИФРА
							</NavButton>
						</li>
						<li>
							<NavButton href={PATHS.CATALOG} type={"text"}>
								Каталог
							</NavButton>
						</li>
						<li>
							<NavButton href={PATHS.CONTACT} type={"text"}>
								Контакты
							</NavButton>
						</li>
					</ul>
				</div>
			</nav>
		</header>
	);
};

export default Header;
